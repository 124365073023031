<template>
  <div v-if="showSleepMessage" class="sleep-message">Sleeping...</div>
  <div v-else>
    <Navbar v-if="user_slug" />
    <UpdateBanner v-if="showUpdateBanner" @refresh="performRefresh" />
    <router-view :key="$route.path" />
  </div>
</template>


<script>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { db, auth } from "@/firebase/config"
import { doc, onSnapshot } from "firebase/firestore"
import { onAuthStateChanged } from "firebase/auth";

import Navbar from './components/Navbar.vue'
import getUser from "@/composables/getUser";
import UpdateBanner from './components/UpdateBanner.vue'


export default {
  components: { Navbar, UpdateBanner },
  setup() {
    const { user_slug } = getUser();
    const mainContentClass = computed(() => {
      return {
        'main-content': user_slug.value,
        'main-content-no-navbar': !user_slug.value,
      };
    });

    const version = ref(null);
    const showUpdateBanner = ref(false);
    const showSleepMessage = ref(false);
    let unsubscribe = null;
    let timer = null;

    // New code: Handle visibility changes, when user minimizes browser it shuts down the app then force refreshes on wakeup
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Start timer when tab is not active
        timer = setTimeout(() => {
          showSleepMessage.value = true;
        }, 60 * 60 * 1000); // 60 minutes
      } else {
        // Clear timer and refresh the page when tab becomes active
        clearTimeout(timer);
        if (showSleepMessage.value) {
          showSleepMessage.value = false;
          // alert('Waking up...');
          location.reload(); // Force a refresh
        }
      }
    };
    onMounted(async () => {
      // if (user_slug.value) {
        const versionRef = doc(db, 'version', 'current');
        // This will run on component mount and whenever Firestore document changes
        unsubscribe = onSnapshot(versionRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const currentVersion = docSnapshot.data().value;
            console.log('Current Version from Firestore: ', currentVersion); // Add this line

            const loadedVersion = localStorage.getItem('loadedVersion');
            console.log('Loaded Version from localStorage: ', loadedVersion); // Add this line

            if (loadedVersion && loadedVersion !== currentVersion) {
              console.log('Found change!')
              showUpdateBanner.value = true;
            }

            // Store the current version as the loaded version in localStorage
            localStorage.setItem('loadedVersion', currentVersion);
            console.log('New Version set in localStorage: ', localStorage.getItem('loadedVersion')); // Add this line

            version.value = currentVersion;
          }
        }, (error) => {
          console.error('Error listening for version changes: ', error);
        });
      // }
      // New code: Add event listener for visibility change
      document.addEventListener('visibilitychange', handleVisibilityChange);

    });

    onUnmounted(() => {
      if (unsubscribe) {
        // Unsubscribe the listener when the component is unmounted
        unsubscribe();
      }

      document.removeEventListener('visibilitychange', handleVisibilityChange);

    });

    const performRefresh = () => {
      window.location.reload();
    };

    return {
      user_slug,
      mainContentClass,
      version,
      showUpdateBanner,
      performRefresh,
      showSleepMessage,  // New code
    }

  }
}
</script>

<style scoped>
.sleep-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  z-index: 10000;
}
</style>