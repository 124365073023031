<template>
  <ShowMessages />
</template>

<script>
import SendAlertForm from '@/components/SendAlertForm.vue'
import ShowMessages from '@/components/ShowMessages.vue'

export default {
  name: 'alerts',
  components: { SendAlertForm, ShowMessages }
}
</script>

<style></style>