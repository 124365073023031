<template>
    <div class="language-container">
        <label id="landingIcon" for="languageSelect">
            <i class="fa-solid fa-globe"></i>
        </label>
        <select name="languageSelect" v-model="selectedLanguage" @change="emitLanguageChange">
            <option value="en">English</option>
            <option value="es">Español</option>
        </select>
    </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
    name: 'LanguageSelector',
    props: {
        initialLanguage: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const selectedLanguage = ref(props.initialLanguage);

        const emitLanguageChange = () => {
            emit('language-changed', selectedLanguage.value);
        };

        watch(selectedLanguage, () => {
            emitLanguageChange();
        });

        return {
            selectedLanguage,
            emitLanguageChange
        };
    }
}
</script>
