<template>
  <ShowMessageThread />
  <!-- <SendAlertForm /> -->
<!-- Need to combine functionality of SendAlertForm into ShowMessageThread -->
</template>

<script>
// import SendAlertForm from '@/components/SendAlertForm.vue'
import ShowMessageThread from '@/components/ShowMessageThread.vue'

export default {
  name: 'show-message-thread',
  // components: { SendAlertForm, ShowMessageThread }
  components: { ShowMessageThread }
}
</script>

<style>

</style>