// updatePlayer.js
// this should no longer be used. Replaced by cloudfunction players-updateplayer
import { db } from "@/firebase/config";
import { doc, setDoc } from "firebase/firestore";

export default function useUpdatePlayer() {

    const updatePlayerData = async (playerId, dataObj) => {
        const playerRef = doc(db, 'players', playerId);

        try {
            await setDoc(playerRef, dataObj, { merge: true });  // { merge: true } ensures updating fields, and creating the doc if it doesn't exist
            console.log('Player data updated/created successfully');
            return { success: true };
        } catch (error) {
            console.error('Error updating/creating player:', error);
            return { success: false, error };
        }
    };

    return { updatePlayerData };
}
