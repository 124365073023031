<template>
  <div class="ev">
    <div v-if="playerRec" class="ev-block">
      <div class="playerProfile">
        <img :src="(playerRec.photoURL ? playerRec.photoURL : 'https://firebasestorage.googleapis.com/v0/b/pickled-sandbox.appspot.com/o/images%2FPurplePickledLogo-Thumb.png?alt=media&token=4cff4290-084b-4cce-b513-1ab6e7a6f832')" alt="Img" />
        <img id="overlay" src="https://firebasestorage.googleapis.com/v0/b/pickled-sandbox.appspot.com/o/images%2FProfile-Overlay.png?alt=media&token=ddb16db3-7b6a-49cf-8854-927a2ed49301" alt="Img" />
        <div id="playerName">{{ playerRec.playerName }}</div>
        <div id="playerJoined"><span style="font-weight: 500;">Drafted:</span> {{ draftDate(playerRec.created) }}</div>
        <div class="playerHighlightWrapper">
          <div class="playerHighlights">
            <i id="gold" class="fa-solid fa-medal"><span>{{ playerRec.medalGold ? playerRec.medalGold : '0' }}</span></i>
            <i id="silver" class="fa-solid fa-medal"><span>{{ playerRec.medalSilver ? playerRec.medalSilver : '0' }}</span></i>
            <i id="bronze" class="fa-solid fa-medal"><span>{{ playerRec.medalBronze ? playerRec.medalBronze : '0' }}</span></i>
            <i id="trophy" class="fa-solid fa-trophy"><span>0</span></i>
            <i id="badge" class="fa-solid fa-award"><span>0</span></i>
          </div>
        </div>
      </div>

      <div class="playerDoc">


        <div class="form-block" style="margin-bottom: 0px; padding-bottom: 2px;">
          <button style="margin-top: 0px; margin-bottom: 3px;" @click="handleGoThread(playerRec.playerName, '')">Message {{ playerRec.playerName }} <i class="fa-solid fa-message"></i></button>
        </div>

        <div class="playerStats">
          <div id="playerHeadline">{{ playerRec.headline }}</div>
          <div id="playerSummary">{{ playerRec.summary }}</div>

          <div id="playerHeadline" v-if="playerRec.youtube || playerRec.facebook || playerRec.instagram || playerRec.twitter || playerRec.medium" style="margin-top: 8px; margin-bottom: 8px; ">My Social Media Pages</div>
          <span v-if="playerRec.youtube" class="playerSocial"><a :href="playerRec.youtube" target="_blank"><i class="fa-brands fa-youtube" style="font-size: 1.5em;"></i></a></span>
          <span v-if="playerRec.facebook" class="playerSocial"><a :href="playerRec.facebook" target="_blank"><i class="fa-brands fa-facebook-square" style="font-size: 1.5em;"></i></a></span>
          <span v-if="playerRec.instagram" class="playerSocial"><a :href="playerRec.instagram" target="_blank"><i class="fa-brands fa-instagram-square" style="font-size: 1.5em;"></i></a></span>
          <span v-if="playerRec.twitter" class="playerSocial"><a :href="playerRec.twitter" target="_blank"><i class="fa-brands fa-twitter" style="font-size: 1.5em;"></i></a></span>
          <span v-if="playerRec.medium" class="playerSocial"><a :href="playerRec.medium" target="_blank"><i class="fa-brands fa-medium" style="font-size: 1.5em;"></i></a></span>


          <div class="form-block">
            <div class="card">
              <h2>Player Stats</h2>
              <hr />
              <div class="grid-container">
                <div class="stat">Points earned</div>
                <div class="value">{{ playerRec.points }}</div>
                <div class="stat">Matches played</div>
                <div class="value">{{ playerRec.wins + playerRec.losses }}</div>
                <div class="stat">Wins</div>
                <div class="value">{{ playerRec.wins }}</div>
                <div class="stat">Losses</div>
                <div class="value">{{ playerRec.losses }}</div>
                <div class="stat">Win%</div>
                <div class="value">{{ (playerRec.wins + playerRec.losses > 0 ? parseFloat((playerRec.wins / (playerRec.wins + playerRec.losses) * 100).toFixed(1)) : 0) }}%</div>
                <div class="stat">Events hosted</div>
                <div class="value">{{ playerRec.hosted ? playerRec.hosted : 0 }}</div>
                <div class="stat">Events attended</div>
                <div class="value">{{ playerRec.attended ? playerRec.attended : 0 }}</div>
                <div class="stat">Players Drafted</div>
                <div class="value">{{ playerRec.drafted ? playerRec.drafted : 0 }}</div>
              </div>
            </div>
          </div>

          <!-- <div class="grid-container-profile">
            <div class="grid-item-attending-header">Skill</div>
            <div class="grid-item-attending-header">Rank</div>
            <div class="grid-item-attending-header">Points</div>
            <div class="grid-item-attending-header">Won</div>
            <div class="grid-item-attending-header">Lost</div>
            <div class="grid-item-attending-header">Hosted</div>
            <div class="grid-item-attending-header">Played</div>
            <div class="grid-item-attending">{{ playerRec.skillSelf ? playerRec.skillSelf : '???' }}</div>
            <div class="grid-item-attending">{{ playerRec.rank ? playerRec.rank : 'NR' }}</div>
            <div class="grid-item-attending">{{ playerRec.points ? playerRec.points : 'NR' }}</div>
            <div class="grid-item-attending">{{ playerRec.wins ? playerRec.wins : '0' }}</div>
            <div class="grid-item-attending">{{ playerRec.losses ? playerRec.losses : '0' }}</div>
            <div class="grid-item-attending">{{ playerRec.hosted ? playerRec.hosted : '0' }}</div>
            <div class="grid-item-attending">{{ playerRec.attended ? playerRec.attended : '0' }}</div>
          </div> -->


        </div>

      </div>
    </div>
  </div>
</template>

<script>
import getPlayerByName from "@/composables/getPlayerByName";
import { ref } from "@vue/reactivity";
import { format } from "date-fns";
// import reloadPage from "@/composables/reloadPage";
// import addLog from "@/composables/addLog"
import getUser from "@/composables/getUser"
import slugify from "slugify";
import { useRouter } from "vue-router";

export default {
  name: "view-player",
  props: ["pName"],
  setup(props) {
    document.body.scrollTop = document.documentElement.scrollTop = 0; // always sc
    const router = useRouter();
    const { user_slug } = getUser()
    const { playerRec } = getPlayerByName(props.pName);

    // drafted
    const draftDate = (dt) => {
      if (dt) {
        // console.log('DTV', format(new Date(dt.toDate()), "yyyy-MM-dd"))
        return format(new Date(dt.toDate()), "MM/dd/yyyy")
      }
      // return format(new Date(dt), "yyyy-MM-dd")
    };

    const handleGoThread = (from, err) => {
      // slugify then lookup to get name?
      if (from && !err) {
        let slug = null;
        slug = slugify(from, {
          replacement: "-",
          remove: /[$*_+~.()'"!\-:@]/g,
          lower: true,
        });
        router.push({ name: "messagethread", params: { pName: slug } });
      }
    };

    // hacky solution to force PWA cache refresh once per day
    // debt: show a spinner while refreshing
    // const checkLatest = async () => {
    //   await reloadPage('PublicProfile', user_slug.value, false) // calling page
    //   await addLog(user_slug.value, 'PublicProfile', 'LoadPage', 'Viewing ' + props.pName) // player, calling page, action
    // }
    // checkLatest()

    return { playerRec, draftDate, handleGoThread };
  },


};
</script>

<style scoped></style>





