import { ref, watchEffect } from 'vue'
import { db, functions } from '@/firebase/config'
import { collection, query, where, orderBy, getDocs, doc, setDoc } from 'firebase/firestore'
import { httpsCallable } from "firebase/functions";

const updateAlertsReadAll = async (pId) => {
    const docsToMarkRead = [];

    // Collection reference
    const collectionRef = query(
        collection(db, 'alerts'),
        where("to", "==", pId),
        where("read", "==", false),
        orderBy("created", "desc")
    );

    const querySnapshot = await getDocs(collectionRef);

    const markReadPromises = querySnapshot.docs.map(async (unread) => {
        try {
            const markRead = httpsCallable(functions, 'documentUpdates-setDocument');
            await markRead({
                collection: "alerts",
                id: unread.id,
                merge: true,
                fields: { read: true }
            });
        } catch (error) {
            console.error(`Error updating alerts to read in cloud function documentUpdates-setDocument`, error);
        }
    });

    // Wait for all promises to resolve
    await Promise.all(markReadPromises);

    // Additional code can be added here if needed

    return;
};

export default updateAlertsReadAll;



// import { ref, watchEffect } from 'vue'
// // firebase imports
// import { db, functions } from '@/firebase/config'
// import { collection, query, where, orderBy, getDocs, doc, setDoc } from 'firebase/firestore'
// import { httpsCallable } from "firebase/functions";

// const updateAlertsReadAll = async (pId) => {
//     const docsToMarkRead = []
//     //collection reference
//     let collectionRef = collection(db, 'alerts')
//     collectionRef = query(collectionRef, where("to", "==", pId))
//     collectionRef = query(collectionRef, where("read", "==", false))
//     collectionRef = query(collectionRef, orderBy("created", "desc"))
//     const querySnapshot = await getDocs(collectionRef);
//     querySnapshot.forEach((unread) => {
//         // unread.data() is never undefined for query doc snapshots
//         docsToMarkRead.push(unread.id)
//         // console.log(unread.id, " => ", unread.data());
//     })
    
//     // console.log('DocList:', docsToMarkRead)
//     docsToMarkRead.forEach((element) => {
//         let markRef = doc(db, 'alerts', element)
//         setDoc(markRef, { read: true }, { merge: true });
//         // console.log('Ind:',element)
//     });


//     return
// }


// export default updateAlertsReadAll