import { db, functions } from "@/firebase/config";
import { doc, setDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const addPlayerLocation = async (pId, lId, setHome = false) => {
    if (pId && lId) {
        try {
            // const createlocation = httpsCallable(functions, 'documentUpdates-setDocument')
            // createlocation({
            //     collection: "playerLocations",
            //     id: pId + lId,
            //     fields: {
            //         created: serverTimestamp(),
            //         locationId: lId,
            //         playerId: pId    
            //     }
            // })
            await setDoc(doc(db, "playerLocations", pId + lId), {
                created: serverTimestamp(),
                locationId: lId,
                playerId: pId
            });
        } catch (error) {
            console.error(`Error adding player location for Player ID: ${pId}, Location ID: ${lId}`, error);
            console.log("Error in adding location")
            throw error;  // rethrowing the error so the calling function is aware an error occurred
        }
        if (setHome) {
            console.log('updating home location')
            try {
                const updatePlayer = httpsCallable(functions, 'players-updatePlayer');
                await updatePlayer({ playerId: pId, fields: { "homeLocation": lId } });


                // await updateDoc(doc(db, "players", pId), {
                //     homeLocation: lId,
                // })
            } catch (error) {
                console.error(`Error setting home location for Player ID: ${pId}, Location ID: ${lId} in cloud function players-updatePlayer`, error);
                throw error;  // rethrowing the error so the calling function is aware an error occurred
            }
        }
    }
}

export default addPlayerLocation;