import { ref, watchEffect } from 'vue'
// firebase imports
import { db, functions } from '@/firebase/config'
import { collection, query, where, orderBy, getDocs, doc, setDoc } from 'firebase/firestore'
import { httpsCallable } from "firebase/functions";


const updateMessagesReadAll = async (msgChannel) => {
    // Collection reference
    const collectionRef = query(
        collection(db, 'messages'),
        where("channel", "==", msgChannel),
        where("read", "==", false),
        orderBy("created", "desc")
    );

    try {
        // Get unread messages
        const querySnapshot = await getDocs(collectionRef);

        // Create an array of message IDs to mark as read
        const docsToMarkRead = querySnapshot.docs.map((unread) => unread.id);

        // Mark messages as read using a cloud function
        const markReadPromises = querySnapshot.docs.map(async (unread) => {
            try {
                const markRead = httpsCallable(functions, 'documentUpdates-setDocument');
                await markRead({
                    collection: "messages",
                    id: unread.id,
                    merge: true,
                    fields: { read: true }
                });
            } catch (error) {
                console.error(`Error updating message in cloud function documentUpdates-setDocument`, error);
            }
        });

        // Wait for all promises to resolve
        await Promise.all(markReadPromises);

        // Mark last message as read
        await httpsCallable(functions, 'documentUpdates-setDocument')({
            collection: "messageLast",
            id: msgChannel,
            merge: true,
            fields: { read: true }
        });

    } catch (error) {
        console.error(`Error updating messages in cloud function`, error);
    }

    return;
};


// const updateMessagesReadAll = async (msgChannel) => {
//     const docsToMarkRead = []
//     //collection reference
//     let collectionRef = collection(db, 'messages')
//     collectionRef = query(collectionRef, where("channel", "==", msgChannel))
//     collectionRef = query(collectionRef, where("read", "==", false))
//     collectionRef = query(collectionRef, orderBy("created", "desc"))
//     const querySnapshot = await getDocs(collectionRef);
//     querySnapshot.forEach((unread) => {
//         // unread.data() is never undefined for query doc snapshots
//         docsToMarkRead.push(unread.id)
//         // console.log(unread.id, " => ", unread.data());
//     })
//     docsToMarkRead.forEach((element) => {
//         let markRef = doc(db, 'messages', element)
//         setDoc(markRef, { read: true }, { merge: true });
//         // console.log('Ind:',element)
//     });

// // now update last message
//     const docRef1 = doc(db, "messageLast", msgChannel);
//     // update last first channel
//     setDoc(docRef1, {
//         read: true
//     }, { merge: true });



//     return
// }


export default updateMessagesReadAll