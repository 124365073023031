<template>
  <Smash :isVisible="!setPlayerName" labelText="Loading player..." labelsubtext="almost done"></Smash>
  <div v-if="setPlayerName">
    <PlayerNameSelect :uid="curUserId" @playerNameSet="handlePlayerNameSet" />
  </div>
  <!-- <div v-else class="ev">
    <h2>**Loading...</h2>
  </div> -->
</template>

<script>
import { ref } from 'vue';
import { useRouter } from "vue-router";
import getUser from "@/composables/getUser";
import { db, auth } from "@/firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import Cookies from "js-cookie";
import PlayerNameSelect from "@/components/PlayerNameSelect.vue";
// new for spinner
import Smash from '@/components/Smash.vue'; // Adjust the path as necessary

export default {
  name: "home",
  components: {
    PlayerNameSelect, Smash
  },
  setup() {
    const router = useRouter();
    const { user, user_slug } = getUser();
    const setPlayerName = ref(false)
    const curUserId = ref(user.value.uid)

    const confirmPlayerReady = async () => {
      try {
        const docSnap = await getDoc(doc(db, "players", user_slug.value));
        if (docSnap.exists()) {
          // const locationFromCookie = Cookies.get("location"); // read last location selected from cookie if set
          const locationFromCookie = ''
          if (docSnap.data().homeLocation && docSnap.data().skillSelf && docSnap.data().setupComplete) {
            const locationToUse = locationFromCookie || docSnap.data().homeLocation; // Use the location from the cookie if available, else use the player's homeLocation
            router.push({ name: "events", params: { locationId: locationToUse }, });
          } else if (docSnap.data().homeLocation && docSnap.data().skillSelf && !docSnap.data().setupComplete) {
            console.log("welcome");
            router.push({ name: "welcome" });
          } else {
            console.log("player setup incomplete");
            router.push({ name: "playerSetup" });
          }
        } else {
          // docSnap.data() will be undefined in this case, boot them
          console.log("Player not found!");
          signOut(auth);
          router.push({ name: "landing" });
        }
      } catch (error) {
        console.error("An error occurred during player confirmation:", error);
        // Handle or show an error message to the user if needed.
      }
    }
    if (user?.value?.displayName) {
      confirmPlayerReady();
    } else {
      console.log('no player name set')
      setPlayerName.value = true
    }
    const handlePlayerNameSet = () => {
      console.log('handlePlayerNameSet')
      setPlayerName.value = false;
      location.reload(); // hacky solution
    }

    return {
      setPlayerName,
      curUserId,
      handlePlayerNameSet

    };
  },
};
</script>