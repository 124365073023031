import { db, functions } from '@/firebase/config'
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import { httpsCallable } from "firebase/functions";

const updatePlayerPhoto = async (p, durl) => { // p = player id
    //const docRef = doc(db, "players", p);
    const updatePlayer = httpsCallable(functions, 'players-updatePlayer');
    try {
    await updatePlayer({playerId: p, fields: { "photoURL": durl + '&dnpa=' + Date.now()}});
    } catch (error) {
        console.error(`Error updating photoURL in cloud function players-updatePlayer`, error);

    }

    // await updateDoc(docRef, {
    //     photoURL: durl + '&dnpa=' + Date.now(),
    // });
    return
}
export default updatePlayerPhoto