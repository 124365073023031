<template>
  <div v-if="!isUpdating" class="ev">
    <div class="ev-block">
      <h1><i class="fa-solid fa-people"></i> Players</h1>
      <h3 style="padding-top: 0px; margin-bottom: 10px">
        {{ playerTotal }} {{ playerLabel }} at this location
      </h3>

      <form @submit.prevent="handleSubmit">
        <div v-if="playerLocationsAll.length" class="form-block">
          <label id="icon" for="evLocation">{{ $t("event_location") }} <i class="fa-solid fa-location-dot"></i></label>
          <select name="playersLocation" v-model="playersLocation" @change="changeLocation(playersLocation)" lass="locationSelect">
            <option v-for="location in locationNames" :key="location.id" v-bind:value="location.id">
              {{ location.name }}
            </option>
          </select>
        </div>

        <div class="ev-flex">
          <div class="divButton" :style="!viewPlayers ? 'background: #5c97a9;' : ''" style="margin-right: 2px" @click="togglePlayers">
            Players
          </div>
          <div class="divButton" :style="!viewClubs ? 'background: #5c97a9;' : ''" style="margin-right: 2px" @click="toggleClubs">
            Clubs
          </div>
        </div>
        <hr />

        <div v-if="playerNames && viewPlayers">


          <!-- <div v-if="evPlayers.length" class="grid-container-attending"> -->
          <div class="grid-container-roster">

            <div class="grid-item-roster-preheader"></div>
            <div class="grid-item-roster-preheader"></div>
            <div class="grid-item-roster-preheader"></div>
            <div class="grid-item-roster-preheader"><i class="fa-solid fa-user-group" :class="friendFilter ? 'friendYes' : ''" @click="handleShowFriend()" style="padding: 2px;"></i></div>
            <div class="grid-item-roster-preheader"><i class="fa-solid fa-heart" :class="favFilter ? 'favYes' : ''" @click="handleShowFav()" style="padding: 2px;"></i></div>
            <div class="grid-item-roster-preheader"><i class="fa-solid fa-crown" :class="adminFilter ? 'adminYes' : ''" @click="handleShowAdmin()" style="padding: 2px;"></i></div>
            <div class="grid-item-roster-header">Player</div>
            <div class="grid-item-roster-header"></div>
            <div class="grid-item-roster-header">Skill</div>
            <div class="grid-item-roster-header">Friend</div>
            <div class="grid-item-roster-header">Fav</div>
            <div class="grid-item-roster-header">Admin</div>

            <template v-for="(pn, index) in paginatedPlayerNames" :key="pn">
              <!-- <div :class="index % 2 == 0 ? 'grid-item-roster' : 'grid-item-roster-odd'">
                <i class="fa-solid fa-crown" style="color: #a67c00;"></i>
              </div> -->

              <div style="text-align: left; padding-left: 2px" :class="index % 2 == 0
                ? 'grid-item-roster playerLink'
                : 'grid-item-roster-odd playerLink'
                " @click="handleGoPlayer(pn.playerName)">
                {{ pn.playerName }}
              </div>
              <div :class="index % 2 == 0 ? 'grid-item-roster' : 'grid-item-roster-odd'">
                <i class="fa-solid fa-messages" @click="handleGoThread(pn.playerName)" style="cursor: pointer"></i>
              </div>
              <div :class="index % 2 == 0 ? 'grid-item-roster' : 'grid-item-roster-odd'
                ">
                {{ pn.skillSelf ? pn.skillSelf : "???" }}
              </div>
              <div :class="index % 2 == 0 ? 'grid-item-roster' : 'grid-item-roster-odd'">
                <i class="fa-solid fa-user-group" :class="{
                  friendYes: playerFriends.includes(pn.id),
                  friendNo: !playerFriends.includes(pn.id),
                }" @click="handleFriends(pn.id, playerFriends.includes(pn.id))"></i>
              </div>
              <div :class="index % 2 == 0 ? 'grid-item-roster' : 'grid-item-roster-odd'">
                <i class="fa-solid fa-heart" :class="{
                  favYes: playerFavs.includes(pn.id),
                  favNo: !playerFavs.includes(pn.id),
                }" @click="handleFav(pn.id, playerFavs.includes(pn.id))"></i>
              </div>

              <div :class="index % 2 == 0 ? 'grid-item-roster' : 'grid-item-roster-odd'">
                <i class="fa-solid fa-crown" :class="{
                  adminYes: locationAdmins.includes(pn.id),
                  adminNo: !locationAdmins.includes(pn.id),
                }" @click="handleAdmins(pn.id, locationAdmins.includes(user_slug))"></i>
              </div>

            </template>
          </div>
          <div class="ev-flex" style="margin-top: 10px">
            <div v-if="currentPage !== 1" class="divButton" @click="previousPage" style="margin-right: 2px">
              <i class="fa-solid fa-arrow-left"></i> Previous
            </div>
            <div v-if="totalPages > 0 && currentPage < totalPages - 0" class="divButton" @click="nextPage" style="margin-left: 2px">
              Next <i class="fa-solid fa-arrow-right"></i>
            </div>
          </div>
          <h2 class="page-info">Page {{ currentPage }} of {{ totalPages }}</h2>
        </div>

        <div v-else>
          <!-- <div v-if="evPlayers.length" class="grid-container-attending"> -->
          <button @click="toggleCreateClub">
            <i class="fa-solid fa-file-plus"></i> Create Club
          </button>
          <!-- create clubs dialogue -->
          <span v-if="createClub">
            <div class="form-block">
              <label id="icon" for="newClubName">Name: </label>
              <input name="newClubName" style="text-align: left" type="text" v-model="v$.newClubName.$model" placeholder="Input club name" />
            </div>
            <div class="form-err" v-if="v$.newClubName.$error">
              <span v-for="error of v$.newClubName.$errors" :key="error.$uid">{{
                error.$message
              }}</span>
            </div>

            <div class="ev-flex">
              <div class="divButton" style="margin-right: 2px" @click="addClub(v$.newClubName.$model, v$.newClubName.$error)">
                Save
              </div>
              <button style="margin-left: 2px" @click="toggleCreateClub">
                Cancel
              </button>
            </div>
          </span>
          <!-- display clubs list -->
          <div v-if="clubNames.length" class="grid-container-clubs">
            <div class="grid-item-clubs-header">Name</div>
            <div class="grid-item-clubs-header">Members</div>
            <div class="grid-item-clubs-header">Edit</div>
            <div class="grid-item-clubs-header">Delete</div>
            <template v-for="(cn, index) in clubNames" :key="cn">
              <!-- <div style="text-align: left" :class="index % 2 == 0 ? 'grid-item-clubs playerLink' : 'grid-item-clubs-odd playerLink'" @click="handleGoPlayer(cn.clubName)">{{ cn.clubName }}</div> -->
              <div style="text-align: left" :class="index % 2 == 0 ? 'grid-item-clubs' : 'grid-item-clubs-odd'
                ">
                {{ cn.clubName }}
              </div>
              <div :class="index % 2 == 0 ? 'grid-item-clubs' : 'grid-item-clubs-odd'
                ">
                {{ cn.members ? cn.members : "0" }}
              </div>
              <div :class="index % 2 == 0 ? 'grid-item-clubs' : 'grid-item-clubs-odd'
                ">
                <i class="fa-solid fa-file-pen" @click="handleEditClub(cn.id, cn.clubName, playersLocation)" style="cursor: pointer"></i>
              </div>
              <div :class="index % 2 == 0 ? 'grid-item-clubs' : 'grid-item-clubs-odd'
                ">
                <i class="fa-solid fa-trash" @click="handleDeleteClub(cn.id)" style="cursor: pointer"></i>
              </div>
            </template>
            <div v-if="clubNames.length" class="grid-item-clubs-footer">
              {{ clubNames.length }} clubs
            </div>
            <!-- <span @click="handleRR">Create Round Robin</span> -->
          </div>
        </div>
      </form>
    </div>
  </div>
  <div v-else class="ev">
    <div class="ev-block">
      <h2>Loading...</h2>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { db, functions } from "@/firebase/config";
import {
  getDoc,
  setDoc,
  doc,
  serverTimestamp,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  documentId,
  addDoc,
} from "firebase/firestore";
import getUser from "@/composables/getUser";
// import reloadPage from "@/composables/reloadPage"
// import addLog from "@/composables/addLog"
import router from "@/router";
import slugify from "slugify";
import { maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { format } from "date-fns";
import usePlayersForLocation from "@/composables/usePlayersForLocation";
import { httpsCallable } from 'firebase/functions';

export default {
  name: "view-players-for-location",
  props: ["locId", "clubActive", "showAdmin"],
  setup(props) {
    // console.log('props.showAdmin', props.showAdmin)
    document.body.scrollTop = document.documentElement.scrollTop = 0; // always sc
    const { user_slug, user_name } = getUser();
    const playersLocation = ref(props.locId);
    const playersLocationLabel = ref("");
    const playerNames = ref([]);
    const playerCount = ref(0);
    const playerTotal = ref(0);
    const clubNames = ref([]);
    const playerFavs = ref([]);
    const playerFriends = ref([]);
    const isUpdating = ref(false);
    const viewPlayers = ref(true);
    const viewClubs = ref(false);
    const newClubName = ref("");
    const v$ = useVuelidate();
    const createClub = ref(false);
    const playerLocationsAll = ref([]);
    const locationNames = ref([]);
    const currentPage = ref(1);
    const itemsPerPage = ref(10);
    const playerLabel = ref('players')
    // get location non-slug
    const getLocation = async () => {
      const docRef = doc(db, "locations", props.locId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        playersLocationLabel.value = docSnap.data().name;
        // console.log("Document data:", docSnap.data().name);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getLocation();

    // this is to populate the location select
    const getPlayerLocationsAll = async () => {
      const q = query(
        collection(db, "playerLocations"),
        where("playerId", "==", user_slug.value),
        where("locationId", "!=", "")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((playerLoc) => {
        playerLocationsAll.value.push(playerLoc.data().locationId);
      });

      const chunkSize = itemsPerPage.value;
      for (let i = 0; i < playerLocationsAll.value.length; i += chunkSize) {
        const chunk = playerLocationsAll.value.slice(i, i + chunkSize);
        const q2 = query(
          collection(db, "locations"),
          where(documentId(), "in", chunk)
        );
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((locRec) => {
          locationNames.value.push({ ...locRec.data(), id: locRec.id });
        });
      }
    };
    getPlayerLocationsAll();

    // togglePlayers
    const togglePlayers = () => {
      viewPlayers.value = true;
      viewClubs.value = false;
    };

    // toggleClubs
    const toggleClubs = () => {
      viewPlayers.value = false;
      viewClubs.value = true;
    };

    if (props.clubActive) {
      toggleClubs();
    }

    // toggleCreateClub
    const toggleCreateClub = () => {
      createClub.value = !createClub.value;
    };

    const { getPlayersForLocation } = usePlayersForLocation(
      playersLocation,
      playerNames,
      playerCount,
      playerTotal
    );

    const loadingPlayers = ref(false)
    onMounted(async () => {
      loadingPlayers.value = true
      await getPlayersForLocation();
      loadingPlayers.value = false
    });

    // get clubNames for this player
    const getClubsForLocation = async () => {
      let results = [];
      const q = query(
        collection(db, "clubs"),
        where("playerId", "==", user_slug.value),
        where("location", "==", playersLocation.value),
        where("owner", "==", true)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        results.push({ ...doc.data(), id: doc.id });
        // console.log(doc.id, " => ", doc.data());
      });
      clubNames.value = results;
    };
    getClubsForLocation();

    //    addClub(n, err) {
    const addClub = async (cn, err) => {
      if (!err) {
        let clubNameId = slugify(cn, {
          replacement: "-",
          remove: /[$*_+~.()'"!\-:@]/g,
          lower: true,
        });

        const clubExists = await getDoc(
          doc(db, "clubs", props.locId + clubNameId)
        );
        if (clubExists.exists()) {
          // console.log("Club Exists:", clubExists.data());
          alert(
            "Sorry, a club with this name already exists for " +
            playersLocationLabel.value +
            ". Please choose another name."
          );
          return false; // do nothing
        } else {
          // doc.data() will be undefined in this case
          // console.log("No club");

          const addClub = httpsCallable(functions, 'documentUpdates-setDocument')
          try {
            await addClub({
              collection: "clubs",
              id: props.locId + clubNameId,
              merge: true,
              fields: {
                clubName: cn,
                playerId: user_slug.value,
                created: serverTimestamp(),
                location: playersLocation.value,
                members: 1,
                owner: true,
              }
            })
          } catch (error) {
            console.error(`Error adding club locatiion in cloud function documentUpdates-setDocument`, error);
          }

          newClubName.value = "";
          await getClubsForLocation();
          toggleCreateClub();
        }
        return true;
      } else {
        return false;
      }
    };
    // get player favorites to flat array
    const getPlayersFavorites = async () => {
      let results = [];
      const qFav = query(
        collection(db, "playerFavorites"),
        where("location", "==", playersLocation.value),
        where("favorite", "==", true),
        where("playerId", "==", user_slug.value)
      );
      const querySnapshotqFav = await getDocs(qFav);
      querySnapshotqFav.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        results.push(doc.data().favoriteId);
        // console.log(doc.data().favoriteId);
      });
      playerFavs.value = results;
    };
    getPlayersFavorites();




    // function to set/unset favorite player
    // reverted to CLI side because cloud randonly takes 2-3 seconds and a spinner would be annoying here
    const handleFav = async (pId, isFav) => {
      await setDoc(
        doc(db, "playerFavorites", user_slug.value + pId),
        {
          playerId: user_slug.value,
          created: serverTimestamp(),
          location: playersLocation.value,
          favorite: isFav ? false : true,
          favoriteId: pId,
        },
        { merge: true }
      );
      await getPlayersFavorites(); // refresh the array
    };

    // const handleFav = async (pId, isFav) => {
    //   const addFavoritePlayer = httpsCallable(functions, 'documentUpdates-setDocument')
    //   try {
    //     await addFavoritePlayer({
    //       collection: "playerFavorites",
    //       id: user_slug.value + pId,
    //       merge: true,
    //       fields: {
    //         playerId: user_slug.value,
    //         created: serverTimestamp(),
    //         location: playersLocation.value,
    //         favorite: (isFav ? false : true),
    //         favoriteId: pId
    //       }
    //     })
    //   } catch (error) {
    //     console.error(`Error adding to playerFavorites in cloud function documentUpdates-setDocument`, error);
    //   }
    //   await getPlayersFavorites(); // refresh the array
    // };

    // get player friends to flat array
    const getPlayersFriends = async () => {
      let results = [];
      const qFriend = query(
        collection(db, "playerFriends"),
        where("location", "==", playersLocation.value),
        where("friend", "==", true),
        where("playerId", "==", user_slug.value),
        orderBy("friendId", "asc")
      );
      const querySnapshotqFriend = await getDocs(qFriend);
      querySnapshotqFriend.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        results.push(doc.data().friendId);
        // console.log(doc.data().friendId);
      });
      playerFriends.value = results;
    };
    getPlayersFriends();

    // function to set/unset friends player
    // reverted to CLI write for same reason
    const handleFriends = async (pId, isFriend) => {
      await setDoc(
        doc(db, "playerFriends", user_slug.value + pId),
        {
          playerId: user_slug.value,
          created: serverTimestamp(),
          location: playersLocation.value,
          friend: isFriend ? false : true,
          friendId: pId,
        },
        { merge: true }
      );
      await getPlayersFriends(); // refresh the array
    };

    // const handleFriends = async (pId, isFriend) => {
    //   const addFriend = httpsCallable(functions, 'documentUpdates-setDocument')
    //   try {
    //     await addFriend({
    //       collection: "playerFriends",
    //       id: user_slug.value + pId,
    //       merge: true,
    //       fields: {
    //         playerId: user_slug.value,
    //         created: serverTimestamp(),
    //         location: playersLocation.value,
    //         friend: (isFriend ? false : true),
    //         friendId: pId
    //       }
    //     })
    //   } catch (error) {
    //     console.error(`Error adding to playerFriends in cloud function documentUpdates-setDocument`, error);
    //   }
    //   await getPlayersFriends(); // refresh the array
    // };

    const handleDeleteClub = async (id) => {
      try {
        // Delete all clubMembers records
        const qDelClub = query(
          collection(db, "clubMembers"),
          where("clubId", "==", id)
        );
        const querySnapshotClub = await getDocs(qDelClub);

        const deleteClubMembers = httpsCallable(functions, 'documentUpdates-deleteDocument');
        const deleteClubPromises = [];

        querySnapshotClub.forEach((docMember) => {
          const deleteClubMemberPromise = deleteClubMembers({
            collection: "clubMembers",
            id: docMember.id
          }).catch((error) => {
            console.error(`Error deleting document ${docMember.id} from collection clubMembers in cloud function documentUpdates-deleteDocument`, error);
          });

          deleteClubPromises.push(deleteClubMemberPromise);
        });

        // Wait for all club members to be deleted
        await Promise.all(deleteClubPromises);

        // Delete the club itself
        const deleteClub = httpsCallable(functions, 'documentUpdates-deleteDocument');
        await deleteClub({
          collection: "clubs",
          id: id
        });
      } catch (error) {
        console.error(`Error deleting club ${id} and associated club members`, error);
      }

      // Additional logic after deletion, e.g., fetching updated club list
      getClubsForLocation();
    };

    // drafted
    const foundedDate = (dt) => {
      if (dt) {
        // console.log('DTV', format(new Date(dt.toDate()), "yyyy-MM-dd"))
        return format(new Date(dt.toDate()), "MM/dd/yyyy");
      }
      // return format(new Date(dt), "yyyy-MM-dd")
    };
    const changeLocation = (nl) => {
      // console.log('NewLoc', nl)
      router.push({ name: "players", params: { locId: nl } });
    };

    const filteredPlayerNames = computed(() => {
      if (favFilter.value) {
        playerTotal.value = playerFavs.value.length
        playerLabel.value = 'favorites'
        return playerNames.value.filter(pn =>
          playerFavs.value.includes(pn.id) // Replace 'id' with the correct property
        );
      } else if (friendFilter.value) {
        playerTotal.value = playerFriends.value.length
        playerLabel.value = 'friends'
        return playerNames.value.filter(pn =>
          playerFriends.value.includes(pn.id) // Replace 'id' with the correct property
        );
      } else if (adminFilter.value) {
        playerTotal.value = locationAdmins.value.length
        playerLabel.value = 'admins'
        return playerNames.value.filter(pn =>
          locationAdmins.value.includes(pn.id) // Replace 'id' with the correct property
        );
      } else {
        playerLabel.value = 'players'
        playerTotal.value = playerNames.value.length
        return playerNames.value;
      }
    });
    const paginatedPlayerNames = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredPlayerNames.value.slice(start, end);
    });

    const totalPages = computed(() => {
      // Use filteredPlayerNames.value.length instead of playerNames.value.length
      //   return Math.ceil(playerNames.value.length / itemsPerPage.value);
      return Math.ceil(filteredPlayerNames.value.length / itemsPerPage.value);
    });


    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };
    // new admin filter stuff
    const adminFilter = ref(false);
    const handleShowAdmin = () => {
      if (loadingPlayers.value) {
        alert('Please wait for players to load before applying a filter.')
        return
      }
      currentPage.value = 1
      friendFilter.value = false
      favFilter.value = false
      adminFilter.value = !adminFilter.value; // Toggle the filter
    };


    // new fav filter stuff
    const favFilter = ref(false);
    const handleShowFav = () => {
      if (loadingPlayers.value) {
        alert('Please wait for players to load before applying a filter.')
        return
      }
      currentPage.value = 1
      friendFilter.value = false
      adminFilter.value = false
      favFilter.value = !favFilter.value; // Toggle the filter
    };

    // new friend filter stuff
    const friendFilter = ref(false);
    const handleShowFriend = () => {
      if (loadingPlayers.value) {
        alert('Please wait for players to load before applying a filter.')
        return
      }
      currentPage.value = 1
      favFilter.value = false
      adminFilter.value = false
      friendFilter.value = !friendFilter.value; // Toggle the filter
    };

    // get admins list flat array
    const locationAdmins = ref([])
    const getLocationAdmins = async () => {
      let results = [];
      const qAdmin = query(
        collection(db, "locationsAdmin"),
        where("locationId", "==", playersLocation.value)
      );
      const querySnapshotqAdmin = await getDocs(qAdmin);
      querySnapshotqAdmin.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        results.push(doc.data().playerId);
      });
      locationAdmins.value = results;
    };
    getLocationAdmins();

    const handleAdmins = async (pId, isAdmin) => {
      if (!isAdmin) {
        alert('Sorry, only an admin can promote another admin. Use the admin filter to view/contact admins for this location.')
        return
      } else {
        if (locationAdmins.value.includes(pId)) {
          alert(`${pId} is already an admin for this location.`)
          return
        }
        const inviteMessage = `[ADMIN INVITE] ${user_slug.value} would like to promote you to location admin at ${playersLocationLabel.value}.  Do you accept?`
        if (confirm(`Send ${pId} an invitation to become an admin at ${playersLocationLabel.value}?`)) {
          let messageDoc = {
            created: serverTimestamp(),
            // type: 'teamReq',
            type: 'adminReq',
            fromId: user_slug.value,
            from: user_slug.value,
            toId: pId,
            to: pId,
            channel: user_slug.value + pId,
            message: inviteMessage,
            locationId: playersLocation.value,
            read: false
          }
          // add to messages
          addDoc(collection(db, "messages"), messageDoc)
          // add/update to last message
          const docRef1 = doc(db, "messageLast", user_slug.value + pId);
          // update last first channel (me)
          setDoc(docRef1, {
            created: serverTimestamp(),
            type: 'txt',
            fromId: user_slug.value,
            from: user_slug.value,
            toId: pId,
            to: pId,
            channel: user_slug.value + pId,
            message: '(' + user_slug.value + '):' + inviteMessage,
            read: false,
            channelPhotoURL: ''
            // channelPhotoURL: myPhotoURL.value ? myPhotoURL.value : ''

          });
          // update last second channel
          const docRef2 = doc(db, "messageLast", pId + user_slug.value);
          setDoc(docRef2, {
            created: serverTimestamp(),
            type: 'txt',
            fromId: pId,
            from: pId,
            toId: user_slug.value,
            to: user_slug.value,
            channel: pId + user_slug.value,
            message: '(Me): ' + inviteMessage,
            read: true, // becasue I sent this
            channelPhotoURL: ''
            // channelPhotoURL: playerPhotoURL.value ? playerPhotoURL.value : ''
          });
          alert('An admin invitation has been sent to ' + pId + ' via in-App messages.')
        }
      }

    }

    return {
      playersLocation,
      playersLocationLabel,
      playerNames,
      playerFavs,
      handleFav,
      playerFriends,
      handleFriends,
      isUpdating,
      viewPlayers,
      viewClubs,
      togglePlayers,
      toggleClubs,
      clubNames,
      newClubName,
      v$,
      createClub,
      toggleCreateClub,
      addClub,
      handleDeleteClub,
      foundedDate,
      playerCount,
      playerTotal,
      playerLocationsAll,
      locationNames,
      changeLocation,
      paginatedPlayerNames,
      currentPage,
      itemsPerPage,
      totalPages,
      nextPage,
      previousPage,
      handleShowFav,
      favFilter,
      friendFilter,
      handleShowFriend,
      locationAdmins,
      handleAdmins,
      adminFilter,
      handleShowAdmin,
      playerLabel,
      user_slug
    };
  },
  methods: {
    handleGoPlayer(n) {
      router.push({
        name: "viewplayer",
        params: {
          pName: slugify(n, {
            replacement: "-",
            remove: /[$*_+~.()'"!\-:@]/g,
            lower: true,
          }),
        },
      });
    },
    handleGoThread(n) {
      router.push({
        name: "messagethread",
        params: {
          pName: slugify(n, {
            replacement: "-",
            remove: /[$*_+~.()'"!\-:@]/g,
            lower: true,
          }),
        },
      });
    },
    handleEditClub(id, name, loc) {
      router.push({
        name: "editclub",
        params: {
          clubId: id,
        },
        query: {
          clubName: name,
          locId: loc,
        },
      });
    },
  },
  validations() {
    return {
      newClubName: { maxLengthValue: maxLength(12) },
    };
  },
};
</script>

<style scoped></style>