<template>
      <ViewPlayersForLocation />
</template>

<script>
import ViewPlayersForLocation from '@/components/ViewPlayersForLocation.vue'

export default {
  name: 'view-players',
  components: { ViewPlayersForLocation }
}
</script>

<style>

</style>