import { db } from "@/firebase/config";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { ref, watchEffect } from 'vue'

const getAlertCount = (pId) => {
    const docCount = ref(null)
    const error = ref(null)
    //collection reference
    let collectionRef = collection(db, 'alerts')
    collectionRef = query(collectionRef, where("to", "==", pId))
    collectionRef = query(collectionRef, where("read", "==", false))
    // update all as read

    // now get
    const unsub = onSnapshot(collectionRef, snapshot => {
        // console.log('count taken')
        // console.log('SS', snapshot.size)
        if (snapshot.size > 0) {
            docCount.value = snapshot.size
        } else {
            docCount.value = 0
        }
        error.value = null
    }, (err) => {
        console.log('alerts', err.message)
        docCount.value = null
        error.value = 'could not get data'
    })
    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())

    })
    return { docCount, error }
}

export default getAlertCount

// const getAlertCount = async (pId) => {
//     const q = query(collection(db, "alerts"), where("to", "==", pId), where("read", "==", false));
//     const querySnapshot = await getDocs(q);
//     console.log('length', querySnapshot.size)
//     // querySnapshot.forEach((doc) => {
//     //     console.log('MyUnread', doc.id, " => ", doc.data());
//     // });
//     return querySnapshot.size

// }
// export default getAlertCount