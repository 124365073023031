import { ref } from 'vue'
import { db } from '@/firebase/config'
import { collection, getDocs, query, where, limit } from 'firebase/firestore'
const getPlayerById = (p) => { // p = player id
    const playerRec = ref([])
    let collectionRef = collection(db, 'players') // establish reference to data
    collectionRef = query(collectionRef, where("userId", "==", p), limit(1))  // add query, should return last event if exists   
    getDocs(collectionRef)
        .then(snapshot => {
            let docs = []
            snapshot.docs.forEach((doc) => {  // using a loop because I couldnt figure out how to get a single doc without first having the id of that doc which I don't know until I query to get users last event created
                docs.push({ ...doc.data(), id: doc.id })
            })
            playerRec.value = docs[0] // assign value of docs to playerRec

        })
    return { playerRec }
}
export default getPlayerById