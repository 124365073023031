// let collectionRef = collection(db, 'players') // establish reference to data
// collectionRef = query(collectionRef, orderBy("created", "desc"), limit(3))

import { db } from "@/firebase/config";
import { collection, query, limit, orderBy, getDocs } from "firebase/firestore";

const getLargestEvents = async () => {
    const q = query(collection(db, "players"), orderBy("maxEventSize", "desc"), limit(5))
    const querySnapshot = await getDocs(q);
    let npl = ''
    // console.log('length', querySnapshot.size)
    querySnapshot.forEach((doc) => {
        // npl = npl + '@' + doc.id + ', '
        npl = npl + doc.data().playerName + '(' + doc.data().maxEventSize + ')' + ', '
        // console.log('MyUnread', doc.id, " => ", doc.data());
    });
    return npl

}
export default getLargestEvents

