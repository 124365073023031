// usePlayersForLocation.js
// returns async list of all players for a given location
// example usage in setup()
// const playersLocation = ref(props.locId)
// const playerNames = ref([])
// const playerCount = ref(0)
// const playerTotal = ref(0)
// const { getPlayersForLocation } = usePlayersForLocation(playersLocation, playerNames, playerCount, playerTotal);
// onMounted(async () => {
//     await getPlayersForLocation();
// });
// ... return { playerNames, playerCount, playerTotal, etc}


import { db } from "@/firebase/config";
import { collection, getDocs, query, where, documentId, orderBy } from "firebase/firestore";

export default function usePlayersForLocation(playersLocation, playerNames, playerCount, playerTotal) {
  
  const getPlayersForLocation = async () => {
    let locResults = []
    let playersForLocation = []
    let counter1 = 0
    playerCount.value = 0
    playerTotal.value = 0
    const q = query(collection(db, "playerLocations"), where("locationId", "==", playersLocation.value), orderBy('playerId'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((playerLoc) => {
      locResults.push({ ...playerLoc.data(), id: playerLoc.id })
      playersForLocation.push(playerLoc.data().playerId)
      counter1++
    })
    playerTotal.value = counter1

    for (let i = 0; i < playersForLocation.length; i += 10) {
      const group = playersForLocation.slice(i, i + 10);
      const qPlayers = query(collection(db, "players"), where(documentId(), "in", group));
      const playerSnapshot = await getDocs(qPlayers);
      playerSnapshot.forEach((playerRec) => {
        playerNames.value.push({ ...playerRec.data(), id: playerRec.id })
        playerCount.value++
      });
    }
  };

  return { getPlayersForLocation };
}
