<template>
  <div class="ev">
    <div class="ev-block">
      <div class="playerPhoto">
        <span v-if="imageData != null">
          <cropper class="cropper" :src="img_src" :stencil-props="{ aspectRatio: 34/40}" ref="cropper">
          </cropper>

        </span>
      </div>
      <div class="formStyleDiv">
        <div class="form-block">
          <div v-if="uploading">Uploaded {{ progress }} %</div>
        </div>
        <!-- <div class="form-block">
          <div v-if="uploadedURL">Uploaded URL: {{ uploadedURL }}</div>
        </div> -->
        <div v-if="imageData == null" class="form-block">
          <button @click="clickPhoto">Click to select photo</button>
          <input type="file" ref="input_file" style="display: none" @change="previewImage" accept=".png, .jpeg, .jpg" />
        </div>
        <div class="form-block">
          <button v-if="imageData != null" @click="create(pId)">
            Crop then click to upload
          </button>
        </div>
        <button @click="goProfile">Back to profile</button>
        <canvas style="display: none;" id="resizedCanvas"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

// import configFirebase from "@/firebase/config";
import { db } from "@/firebase/config";
import { doc, setDoc, updateDoc } from "@firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import router from "@/router";
import getUser from "@/composables/getUser";
import updatePlayerPhoto from "@/composables/updatePlayerPhoto";

export default {
  name: "image-upload",
  props: ["pId"],
  components: {
    Cropper
  },
  data() {
    return {
      imageData: null,
      img_src: null,
      progress: 0,
      uploading: false,
      uploadedURL: null,
      croppedImage: null,
      // photoName: "Ben",

    };
  },
  methods: {
    // DEBT: this should be a composable!!!!
    async create(pId) {
      const { canvas } = this.$refs.cropper.getResult();
      const { user } = getUser();
      this.croppedImage = canvas.toDataURL();

      // let ResizedImg = document.getElementById('cropedProfile');
      let ResizedCanvas = document.getElementById("resizedCanvas");
      ResizedCanvas.height = ResizedCanvas.width = 0;
      var context = ResizedCanvas.getContext('2d');
      var imgwidth = canvas.width;
      var imgheight = canvas.height;
      ResizedCanvas.width = 340;
      ResizedCanvas.height = 400;
      context.drawImage(
        canvas, 0, 0, imgwidth, imgheight, 0, 0, 340, 400
      );
      ResizedCanvas.toBlob(blob => {
        this.imageData = blob;
        let storage = getStorage();
        const storageRef = ref(storage, "players/"+user.value.uid+"/photo/profile.png");
        // const storageRef = ref(storage, `players/${user.value.uid}/photo/${this.imageData.name}`);
        uploadBytes(storageRef, blob).then((snapshot) => {
          // console.log("uploaded--------------------------", snapshot);
          getDownloadURL(storageRef).then(function (downloadURL) {
            updatePlayerPhoto(pId, downloadURL).then(() => {
              // console.log('updated PHOTO')
              router.push({ name: "player" })
            })
          });
        })
      })

    },
    clickPhoto() {
      this.$refs.input_file.click();
    },
    previewImage(event) {
      this.progress = 0;
      this.imageData = event.target.files[0];
      this.showImage();
    },
    showImage() {
      var fr = new FileReader();
      fr.onload = () => {
        this.img_src = fr.result;
      };
      fr.readAsDataURL(this.imageData);
    },
    goProfile() {
      router.push({ name: "player" });
    },
  },
};
</script>

