<template>
    <div v-if="isVisible" class="smash-container">
        <img src="@/assets/BallSpin.gif" alt="Spinning Ball Animation">
        <div class="loading-text">{{ computedLabelText }}</div> <!-- Use the computed property -->
        <div class="loading-subtext">{{ computedSubtext }}</div> <!-- Use the computed property -->
    </div>
</template>

<script>
import { toRefs, computed } from 'vue';

export default {
    name: 'Smash',
    props: {
        isVisible: Boolean,
        labelText: String, // This prop can be optionally provided
        labelsubtext: String, // This prop can be optionally provided
    },
    setup(props) {
        const { isVisible, labelText, labelsubtext } = toRefs(props);
        const labelOptions = [
            "Hang tight",
            "Please wait",
            "Hold your horses",
            "Just a sec",
            "Buckle up, Buttercup!"
        ];

        const subtextOptions = [
            "contacting mothership",
            "preparing for launch",
            "brewing coffee",
            "pondering life",
            "taking out the trash",
            "pickleball is life"
        ];

        // Computed property to determine what labelText to use
        const computedLabelText = computed(() => {
            // If labelText is provided, use it; otherwise, select a random label from the array
            return labelText.value || labelOptions[Math.floor(Math.random() * labelOptions.length)];
        });
        const computedSubtext = computed(() => {
            if (computedLabelText.value == "Buckle up, Buttercup!") {
                return "preparing for launch"
            }
            // If labelText is provided, use it; otherwise, select a random label from the array
            return labelsubtext.value || subtextOptions[Math.floor(Math.random() * subtextOptions.length)];
        });

        // Return the isVisible and computedLabelText so they're available in the template
        return { isVisible, computedLabelText, computedSubtext };
    },
};
</script>

<style scoped>
/* No changes to the CSS */
.smash-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
}

.loading-text {
    color: white;
    font-size: 36px;
    font-weight: 700;
    position: absolute;
    top: 485px;

    @media (max-width: 1025px) {
        top: 485px;
    }

    @media (max-width: 489px) {
        font-size: 30px;
        top: 300px;
    }
}

.loading-subtext {
    color: white;
    font-size: 36px;
    font-weight: 200;
    /* font-style: italic; */
    position: absolute;
    top: 530px;

    @media (max-width: 1025px) {
        top: 530px;
    }

    @media (max-width: 489px) {
        font-size: 30px;
        top: 340px;
    }
}



img {
    max-width: 280px;
    max-height: 280px;
    width: auto;
    height: auto;
    position: absolute;
    top: 200px;

    @media (max-width: 1025px) {
        top: 150px;
    }

    @media (max-width: 489px) {
        top: 115px;
        max-width: 180px;
        max-height: 180px;
    }
}
</style>
