<template>
      <ImageUpload />
</template>

<script>
import ImageUpload from '@/components/ImageUpload.vue'

export default {
  name: 'image-upload',
  components: { ImageUpload }
}
</script>

<style>

</style>