import { ref, watchEffect } from 'vue'
// firebase imports
import { db } from '@/firebase/config'
import { collection, onSnapshot, query, where, orderBy, limit } from 'firebase/firestore'

const getAlertsArch = (pId) => { 
    const documentsArch = ref(null)
    const errorArch = ref(null)
    //collection reference
    let collectionRef = collection(db, 'alerts')
    collectionRef = query(collectionRef, where("to", "==", pId))
    collectionRef = query(collectionRef, where("read", "==", true))
    collectionRef = query(collectionRef, orderBy("created", "desc"), limit(20))
// update all as read

// now get
    const unsub = onSnapshot(collectionRef, snapshot => {
        // console.log('arch snapshot taken')
        let results = []
        snapshot.docs.forEach(doc => {
            doc.data().created && results.push({ ...doc.data(), id: doc.id })
        })
        // update values
        documentsArch.value = results
        errorArch.value = null
    }, (err) => {
        console.log(err.message)
        documentsArch.value = null
        errorArch.value = 'could not get data'
    })
    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())

    })
    return { documentsArch, errorArch }
}


export default getAlertsArch