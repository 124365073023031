<template>
    <section class="landing-grid-container">
        <div class="landing-grid-cell">
            <ul>
                <li>{{ t("game_signups") }}</li>
                <li>{{ t("scorekeeping") }}</li>
                <li>{{ t("eventsPrivate") }}</li>
                <li>{{ t("messaging") }}</li>
                <li>{{ t("alerts") }}</li>
            </ul>
        </div>
        <div class="landing-grid-cell">
            <ul>
                <li>{{ t("leagues") }}</li>
                <li>{{ t("tournaments") }}</li>
                <li>{{ t("automated_matches") }}</li>
                <li>{{ t("personal_history") }}</li>
                <li>{{ t("social") }}</li>
            </ul>
        </div>
    </section>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
    name: 'FeatureList',
    setup() {
        const { t } = useI18n();
        return {
            t // directly using t in the template for simplicity.
        };
    }
};
</script>
