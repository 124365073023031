import { ref } from 'vue'
import { auth } from '@/firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import slugify from "slugify"

const user = ref(auth.currentUser)
const user_slug = ref('')
const user_name = ref('')
onAuthStateChanged(auth, (_user) => {
    user.value = _user
    if (user.value && user.value.displayName) {
        user_name.value = user.value.displayName
        user_slug.value = slugify(user.value.displayName, {
            replacement: '-',
            remove: /[$*_+~.()'"!\-:@]/g,
            lower: true
        })
    } else {
        user_slug.value = ''
    }
})

if (user.value && user.value.displayName) {
    user_name.value = user.value.displayName
    user_slug.value = slugify(user.value.displayName, {
        replacement: '-',
        remove: /[$*_+~.()'"!\-:@]/g,
        lower: true
    })
} else {
    user_slug.value = ''
}

const getUser = () => {
    return { user, user_slug, user_name }
}

export default getUser