<template>
  <Smash :isVisible="loadingData" labelText="Initializing player" labelsubtext="almost done"></Smash>
  <div v-if="!loadingData" class="ev-block" style="height: auto;">
    <div class="playerDoc">
      <div class="largeIcon">
        <i class="fa-solid fa-address-card"></i>
      </div>
      <div class="playerStats">
        <div id="playerHeadline">Choose Player Name</div>
        <hr />
        <div class="form-block">
          <label id="icon" for="player">
            <i class="fa-solid fa-user-large"></i>
          </label>
          <input style="text-align: left" type="text" inputmode="text" autocomplete="off" name="player" maxlength="12" :placeholder="$t('choosePlayerName')" v-model="v$.reqPlayerName.$model" />
          <!-- <input style="text-align: left" type="text" inputmode="text" autocomplete="off" name="player" maxlength="12" :placeholder="$t('choosePlayerName')" v-model="v$.player.$model" /> -->
        </div>
        <div class="form-err" v-if="v$.reqPlayerName.$error">
          <span v-for="error of v$.reqPlayerName.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div v-if="disableCheckButton" class="divButtonDisabled">{{ buttonLabel }}</div>

        <div v-else-if="wasNameChecked">
          <div v-if="nameAvailable">{{ reqPlayerName }} is available!</div>
          <div v-if="nameAvailable" class="divButton" style="background-color: #6d11ad;" @click="setPlayerName">Set Player Name</div>
          <div v-else>Name not available, try again.</div>
        </div>

        <div v-else class="divButton" @click="playerAvailable">Check Availability</div>

        <hr />
        <div id="playerSummary">Choose a unique player name that represents your public persona. This name will be displayed on your profile and for every event you join. The name can be a maximum of 12 characters.</div>
        <div id="playerSummary">Once set, your player name cannot be changed. Choose wisely!</div>
        <div class="divButton" @click="handleCancel">Cancel</div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="ev-block">
      <div class="playerDoc">
        <div id="playerHeadline">Loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import slugify from 'slugify'
import { required, alphaNum, maxLength, minLength, helpers } from '@vuelidate/validators'
import { httpsCallable } from 'firebase/functions'
import { db, functions, auth } from '@/firebase/config'
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore'
import { updateProfile, signOut } from 'firebase/auth'
import useUpdatePlayer from '@/composables/updatePlayer'
import getUser from '@/composables/getUser'
import addPlayerLocation from '@/composables/addPlayerLocation'
// new for spinner
import Smash from '@/components/Smash.vue' // Adjust the path as necessary

export default {
  name: 'PlayerNameSelect',
  emits: ['playerNameSet'],
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  components: {
    Smash,
  },
  setup(props, { emit }) {
    const { user } = getUser()
    const { updatePlayerData } = useUpdatePlayer()
    const buttonLabel = ref('Check Availability')
    const loadingData = ref(false)
    const router = useRouter()
    const { t } = useI18n()
    const handleLanding = () => {
      router.push('/landing')
    }
    const continueSignIn = () => {
      router.push({ name: 'signIn', query: { justRegistered: true } })
    }

    const handleCancel = () => {
      signOut(auth)
      router.push({ name: 'landing' })
    }
    const reqPlayerName = ref('')
    const v$ = useVuelidate()
    const disableCheckButton = ref(true)
    const nameAvailable = ref('')
    const wasNameChecked = ref(false)
    // new background wakeup calls to all pending cloud functions
    httpsCallable(functions, 'validations-checkPlayer')({ id: 'wakeUp' })
    httpsCallable(functions, 'documentUpdates-updateDocument')({ id: 'wakeUp' })
    httpsCallable(functions, 'players-updatePlayer')({ playerId: 'wakeUp' })
    httpsCallable(functions, 'players-updatePlayerPrivate')({ playerId: 'wakeUp' })
    // end new wakup stuff

    const playerAvailable = async () => {
      disableCheckButton.value = true
      wasNameChecked.value = true
      buttonLabel.value = 'Checking, please wait...'
      if (reqPlayerName.value > '') {
        let slug = null
        slug = slugify(reqPlayerName.value.trim(), {
          replacement: '-',
          remove: /[$*_+~.()'"!\-:@]/g,
          lower: true,
        })
        // query cloud function to see if player name already exists
        const nameOk = httpsCallable(functions, 'validations-checkPlayer')
        try {
          const result = await nameOk({ id: slug })
          disableCheckButton.value = false
          nameAvailable.value = !result.data.playerExists

          return !result.data.playerExists
        } catch (error) {
          console.error(`Error validating player in cloud function validations-checkPlayer`, error)
        }
      } else {
        console.log('no name input')
        nameAvailable.value = false
        disableCheckButton.value = false
        return true
      }
    }

    const setPlayerName = async () => {
      loadingData.value = true
      disableCheckButton.value = true
      buttonLabel.value = 'Updating player..'
      try {
        const playerStageCollection = collection(db, 'playerStage')
        //going to need to pull uid in from parent
        const q = query(playerStageCollection, where('userId', '==', props.uid))
        const querySnapshot = await getDocs(q)
        // If a document is found, update the playerName field
        if (!querySnapshot.empty) {
          const docId = querySnapshot.docs[0].id // Assuming there's only one document per user
          const updateplayerStage = httpsCallable(functions, 'documentUpdates-updateDocument')
          try {
            await updateplayerStage({ collection: 'playerStage', fields: { playerName: reqPlayerName.value }, id: docId })
          } catch (error) {
            console.error(`Error updating playerName in cloud function documentUpdates-updateDocument`, error)
          }

          // const playerDocRef = doc(playerStageCollection, docId);
          // await updateDoc(playerDocRef, {
          //     playerName: reqPlayerName.value
          // });
          console.log('Player name updated successfully')
          // Update the authentication displayName to the new playerName
          try {
            await updateProfile(user.value, {
              displayName: reqPlayerName.value,
            })
            console.log('Auth display name updated successfully')
            // now update players collection
            let slug = slugify(reqPlayerName.value, {
              replacement: '-',
              remove: /[$*_+~.()'"!\-:@]/g,
              lower: true,
            })

            // Get all fields from the document, excluding the id
            let playerData = { ...querySnapshot.docs[0].data() }
            playerData.playerName = reqPlayerName.value
            delete playerData.id // remove the id field

            const updatePlayer = httpsCallable(functions, 'players-updatePlayer')
            const result = await updatePlayer({ playerId: slug, fields: playerData })

            const updatePlayerPrivate = httpsCallable(functions, 'players-updatePlayerPrivate')
            const resultPrivate = await updatePlayerPrivate({ playerId: slug, fields: { userId: playerData.userId } })

            if (result.data.success && resultPrivate.data.success) {
              console.log('player updated')
              // add homeLocation to favorites
              if (playerData.homeLocation) {
                // here
                loadingData.value = true
                await addPlayerLocation(slug, playerData.homeLocation)
                loadingData.value = false
              }
              emit('playerNameSet')
              // router.push({ name: "home" });
              // location.reload();
            } else {
              loadingData.value = false
              console.log('player NOT updated')
            }
          } catch (profileError) {
            loadingData.value = false
            console.error('Error updating auth display name:', profileError)
          }
        } else {
          loadingData.value = false
          console.log('No document found for the given user ID')
        }
      } catch (error) {
        loadingData.value = false
        console.error('Error updating player name:', error)
      }
      loadingData.value = false
      disableCheckButton.value = false
      buttonLabel.value = 'Done!'
    }

    watch(reqPlayerName, (newValue, oldValue) => {
      // If the player name was changed after a check
      if (wasNameChecked.value && newValue !== oldValue) {
        wasNameChecked.value = false
        nameAvailable.value = false
      }
      if (newValue && newValue.length >= 3) {
        disableCheckButton.value = false
      } else {
        disableCheckButton.value = true
      }
    })

    return {
      t,
      handleLanding,
      continueSignIn,
      reqPlayerName,
      v$,
      playerAvailable,
      disableCheckButton,
      nameAvailable,
      wasNameChecked,
      setPlayerName,
      buttonLabel,
      handleCancel,
      loadingData,
    }
  },
  validations() {
    return {
      reqPlayerName: {
        required: helpers.withMessage('Please input a player name. ', required),
        maxLength: helpers.withMessage('12 character max. ', maxLength(12)),
        minLength: helpers.withMessage('3 character min. ', minLength(3)),
      },
    }
  },
}
</script>


<style scoped>
.largeIcon {
  font-size: 200px;
  text-align: center;
  color: #00317f;
}

@media screen and (max-width: 489px) {
  .largeIcon {
    font-size: 150px;
    text-align: center;
    color: #00317f;
  }
}

@media screen and (max-width: 374px) {
  .largeIcon {
    font-size: 125px;
    text-align: center;
    color: #00317f;
  }
}
</style>