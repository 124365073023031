<template>
    <div class="white-background-div">
        <div class="get-started-heading">{{ t('get_started') }}</div>
        <div class="auth-grid">
            <div class="auth-option" @click="handleSignin">{{ t('signin') }}</div>
            <div class="auth-option" @click="handleRegister">{{ t('registerButton') }}</div>
        </div>
        <div class="termsPrivacy">
            <span @click="handleTerms">{{ t('termsOfUse') }}</span> |
            <span @click="handlePrivacy">{{ t('privacyPolicy') }}</span>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";

export default {
    name: 'AuthOption',
    setup() {
        const router = useRouter();
        const { t } = useI18n();


        const handleSignin = () => {
            router.push("/signin");
        };
        const handleRegister = () => {
            router.push("/register");
        };

        const handleTerms = () => {
            router.push("/terms");
        };
        const handlePrivacy = () => {
            router.push("/privacy");
        };

        return {
            t,
            handleSignin,
            handleRegister,
            handleTerms,
            handlePrivacy
        };
    }
};
</script>

<style scoped>
/* Your styles can be ported over here from the main.css, if any specific to this component */
</style>
