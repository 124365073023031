import { db } from "@/firebase/config";
import { collection, onSnapshot, query, where, orderBy, limit } from "firebase/firestore";
import { ref, watchEffect } from 'vue'

const getMessageCount = (pId) => {
    const msgCount = ref(null)
    const error = ref(null)
    //collection reference
    let collectionRef = collection(db, 'messages')
    collectionRef = query(collectionRef, where("toId", "==", pId))
    collectionRef = query(collectionRef, where("read", "==", false))
    collectionRef = query(collectionRef, orderBy("created", "desc"), limit(99))

    // now get
    const unsub = onSnapshot(collectionRef, snapshot => {
        // console.log('count taken')
        // console.log('SS', snapshot.size)
        if (snapshot.size > 0) {
            msgCount.value = snapshot.size
        } else {
            msgCount.value = 0
        }
        error.value = null
    }, (err) => {
        console.log('alerts', err.message)
        msgCount.value = null
        error.value = 'could not get data'
    })
    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())

    })
    return { msgCount, error }
}

export default getMessageCount

