<template>
    <div class="ev">
        <div class="ev-block">
            <!-- <div class="logo">
                <img src="@/assets/PickledLogoTrans.png" alt="Pickled.App" />
            </div> -->
            <div class="playerDoc">
                <div class="playerStats">
                    <div id="playerHeadline" style="font-size: 32px; padding-top: 0px;">App Updated!</div>
                    <hr />
                    <div id="playerSummary" style="font-weight: 500;">{{ selectedUpdateMessage }}</div>
                    <div style="font-size: 14px; fon-weight: 300;">Click refresh to receive the latest version.
                    </div>


                    <div class="divButton" @click="$emit('refresh')">Refresh</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            updateMessages: [
                "While you were away, our code gnomes crafted a brand new update!",
                "Our developers must be powered by rocket fuel - we have another update for you!",
                "We're on fire today! Another update is ready for takeoff!",
                "More exciting features have been added to Pickled.App!"
            ],
            selectedUpdateMessage: ''
        }
    },
    created() {
        this.selectedUpdateMessage = this.updateMessages[Math.floor(Math.random() * this.updateMessages.length)];
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        }
    }
}
</script>