<template>
  <ShowAlerts />
</template>

<script>
import SendAlertForm from '@/components/SendAlertForm.vue'
import ShowAlerts from '@/components/ShowAlerts.vue'

export default {
  name: 'alerts',
  components: { SendAlertForm, ShowAlerts }
}
</script>

<style></style>