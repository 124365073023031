import { ref } from 'vue'
import { db } from '@/firebase/config'
import { collection, getDocs, query, where, limit, documentId } from 'firebase/firestore'
const getPlayerByName = (p) => { // p = player id
    const playerRec = ref([])
    let collectionRef = collection(db, 'players') // establish reference to data
    collectionRef = query(collectionRef, where(documentId(), "==", p), limit(1))     
    getDocs(collectionRef)
        .then(snapshot => {
            let docs = []
            snapshot.docs.forEach((doc) => {  // using a loop because I couldnt figure out how to get a single doc without first having the id of that doc which I don't know until I query to get users last event created
                docs.push({ ...doc.data(), id: doc.id })
            })
            playerRec.value = docs[0] // assign value of docs to playerRec
            // console.log('playerRec.value', playerRec.value, p)
        })
    return { playerRec }
}
export default getPlayerByName