<template>
      <PlayerPublicProfile />
</template>

<script>
import PlayerPublicProfile from '@/components/PlayerPublicProfile.vue'

export default {
  name: 'player-public-profile',
  components: { PlayerPublicProfile }
}
</script>

<style>
</style>