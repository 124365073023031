<template>
  <div class="landing-container">
    <LanguageSelector :initialLanguage="language" @language-changed="changeLanguage" />
    <LogoHeading />
    <FeatureList />
    <HelpLinks />
    <div class="flexible-spacer"></div>
    <AuthOption />
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import Cookies from "js-cookie";
import LanguageSelector from '@/components/LanguageSelector.vue';
import LogoHeading from '@/components/LogoHeading.vue';
import FeatureList from '@/components/FeatureList.vue';
import HelpLinks from '@/components/HelpLinks.vue';
import AuthOption from '@/components/AuthOption.vue';
export default {
  components: {
    LanguageSelector,
    LogoHeading,
    FeatureList,
    HelpLinks,
    AuthOption
  },
  setup() {
    document.body.scrollTop = document.documentElement.scrollTop = 0; // always scroll to top on load
    const { locale } = useI18n();
    const language = ref(locale.value);

    const changeLanguage = (lang) => {
      locale.value = lang;
      Cookies.set("language", lang, { expires: 365 }); // store the language for 365 days
    };

    const loadLanguagePreference = () => {
      const savedLanguage = Cookies.get("language");
      if (savedLanguage) {
        locale.value = savedLanguage;
      }
    };
    onMounted(() => {
      loadLanguagePreference();
      document.body.classList.add('special-body-bg');
      document.documentElement.classList.add('special-body-bg');
    });

    onBeforeUnmount(() => {
      document.body.classList.remove('special-body-bg');
      document.documentElement.classList.remove('special-body-bg'); // This removes the class from the html element
    })

    return {
      changeLanguage,
      loadLanguagePreference,
      language,
    };
  },
};
</script>

