import { ref, watchEffect } from 'vue'
// firebase imports
import { db } from '@/firebase/config'
import { collection, onSnapshot, query, where, orderBy, limit } from 'firebase/firestore'

const getMessageLast = (pId) => { 
    const docMsgLast = ref(null)
    const errMsgLast = ref(null)
    //collection reference
    let collectionRef = collection(db, 'messageLast')
    collectionRef = query(collectionRef, where("toId", "==", pId))
    collectionRef = query(collectionRef, orderBy("created", "desc"))
    // collectionRef = query(collectionRef, limit("10"))
// update all as read

// now get
    const unsub = onSnapshot(collectionRef, snapshot => {
        // console.log('snapshot taken')
        let results = []
        snapshot.docs.forEach(doc => {
            doc.data().created && results.push({ ...doc.data(), id: doc.id })
        })
        // update values
        docMsgLast.value = results
        errMsgLast.value = null
    }, (err) => {
        console.log(err.message)
        docMsgLast.value = null
        errMsgLast.value = 'could not get data'
    })
    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())

    })
    return { docMsgLast, errMsgLast }
}


export default getMessageLast