<template>
    <div class="landing-logo" @click="handleLanding">
        <img src="@/assets/PurplePickledLogo-Circle.png" alt="Pickled.App" />
    </div>
    <div class="welcome-text">{{ t('welcome') }}</div>
    <div class="welcome-subtext">{{ t('theBestPlayground') }} &trade;</div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
export default {
    name: 'LogoHeading',
    setup() {
        const router = useRouter();
        const { t } = useI18n();
        const handleLanding = () => {
            router.push("/landing");
        };

        return {
            t,
            handleLanding
        };
    }
};
</script>
