/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available and will be used when all tabs for this page are closed. See https://cra.link/PWA');

      const lastUpdatePrompt = localStorage.getItem('lastUpdatePrompt');
      const now = Date.now();

      // Only show the confirm box if it hasn't been shown in the last 5 minutes
      if (!lastUpdatePrompt || now - lastUpdatePrompt > 5 * 60 * 1000) {
        localStorage.setItem('lastUpdatePrompt', now);

        alert('Installing new version...');
        if (registration && registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        window.location.reload();
      }
    },
    // updated(registration) {
    //   console.log('New content is available and will be used when all tabs for this page are closed. See https://cra.link/PWA');
    //   // Prompt the user to refresh the page, this can be removed so it happens automatically but can hurt user experience.
    //   if (confirm('A new version is available. Click OK to refresh.')) {
    //     // Send the SKIP_WAITING message to the new service worker
    //     if (registration && registration.waiting) {
    //       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    //     }
    //     window.location.reload();
    //   }
    // },
    // updated () {
    //   console.log('New content is available; please refresh.')
    // },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
