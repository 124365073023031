<template>
  <div class="ev">
    <div class="ev-block">
      <h1>Out of bounds!</h1>
      <h3>(404 page not found)</h3>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>