<template>
    <section class="landing-grid-container">
        <div class="landing-grid-cell-center">
            <a href="https://www.facebook.com/groups/1429905987777664" target="_blank" rel="noopener noreferrer">
                <label id="landingIcon"><i class="fa-brands fa-facebook"></i></label>
                <span class="social-text">Facebook</span>
            </a>
        </div>

        <div class="landing-grid-cell-center">
            <a href="https://pickled.medium.com/list/tutorials-1d42d89372c9" target="_blank" rel="noopener noreferrer">
                <label id="landingIcon"><i class="fa-brands fa-medium"></i></label>
                <span class="social-text">{{ t("tutorials") }}</span>
            </a>
        </div>
    </section>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
    name: 'HelpLinks',
    setup() {
        const { t } = useI18n();

        return {
            t
        };
    }
};
</script>