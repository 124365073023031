import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// PROD
// const firebaseConfig = {
//     apiKey: "AIzaSyBjVtCpmIE8cOkHANHWuyWQ1doAlr4_pyI",
//     authDomain: "pickled-prod.firebaseapp.com",
//     projectId: "pickled-prod",
//     storageBucket: "pickled-prod.appspot.com",
//     messagingSenderId: "625177527479",
//     appId: "1:625177527479:web:352072cffab830868f3932",
//     measurementId: "G-M7K4VM932W"
// };

// DEV
const firebaseConfig = {
    // apiKey: "AIzaSyAfQWT2VwsYlbJTXDI5nmB9sCO231p8WOM",
    // authDomain: "pickled-test1.firebaseapp.com",
    // projectId: "pickled-test1",
    // storageBucket: "pickled-test1.appspot.com",
    // messagingSenderId: "497431915384",
    // appId: "1:497431915384:web:da7b025996306c3870b68d"
    apiKey: "AIzaSyD7gdjUkhZcpiiQj_Cpfm5P5uRg9ihMxws",
    authDomain: "pickled-sandbox.firebaseapp.com",
    projectId: "pickled-sandbox",
    storageBucket: "pickled-sandbox.appspot.com",
    messagingSenderId: "484914548771",
    appId: "1:484914548771:web:2d3eac8bd6d00ae34c2196",
    measurementId: "G-YB1KTFRVC4"
};

// init firebase
initializeApp(firebaseConfig)

// init services
const db = getFirestore()
const auth = getAuth()
const stor = getStorage()
const functions = getFunctions()

export { db, auth, stor, functions }